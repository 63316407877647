import { render, staticRenderFns } from "./PartnersList.vue?vue&type=template&id=86e90136&scoped=true"
import script from "./PartnersList.vue?vue&type=script&lang=js"
export * from "./PartnersList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86e90136",
  null
  
)

export default component.exports